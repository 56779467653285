.hero {
  background-image: var(--grad-midnight);
  color: var(--white);
  border-radius: 25px;
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
  // position: relative;
  overflow: visible;
  // z-index: 99;

  @media (min-width: 576px) {
    padding: 2rem 1.5rem;
  }
  @media (min-width: 768px) {
    padding: 2rem 8rem;
  }

  &.hide-bgImg {
    background-image: none;
    margin-bottom: 0;
    padding: 0;

    .ribbon {
      display: none;
    }
    .row-1 {
      display: none;
    }
  }

  // .ribbon {
  //   display: none;
  //   // overflow: hidden;
  //   @media (min-width: 576px) {
  //     // display: block;
  //     display: none;
  //     background-color: #fdda0d;
  //     color: var(--black);
  //     font-family: var(--heading-font);
  //     font-size: 1.2rem;
  //     text-align: center;
  //     position: absolute;
  //     padding: 0.8rem 0;
  //     width: 14rem;
  //     top: 32px;
  //     right: -54px;

  //     transform: rotate(45deg);

  //     white-space: nowrap;
  //   }

  //   .move-letter {
  //     animation: bounce 1s infinite;
  //     display: inline-block;
  //     margin-left: 0.2rem;
  //   }

  //   @keyframes bounce {
  //     0% {
  //       transform: translateY(0);
  //     }
  //     50% {
  //       transform: translateY(-5px);
  //     }
  //     100% {
  //       transform: translateY(0);
  //     }
  //   }
  // }

  .row-1 {
    margin-bottom: 2rem;
    // border: 1px solid white;
    position: relative;

    @media (min-width: 1200px) {
      display: flex;
    }
    .title {
      font-size: 2.2rem;
      font-weight: bolder;

      @media (min-width: 768px) {
        font-feature-settings: "rvrn" on, "hist" on, "cv03" on, "cv04" on,
          "cv15" on, "cv22" on, "liga" off, "clig" off, "calt" off;
        font-family: "Bebas Neue";
        font-size: 4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 4.5rem;
      }
    }
  }

  .row-2 {
  }
}
