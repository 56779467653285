.home-layout {
  background-color: var(--gray-50);
  .main {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    // margin-top: 1rem;

    &.howitworks {
      padding: 1rem;
    }
  }
}
