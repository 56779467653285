.browse-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 10rem);
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;

  &.active {
    margin-top: 5rem;
  }

  &.hidden {
    display: none;
  }

  .left-column,
  .right-column {
    flex: 1;
    padding: 10px;
  }

  .right-column {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    height: 100%;
    background-color: white;
    z-index: 99999;
    transition: bottom 0.5s ease-in-out;

    @media (min-width: 992px) {
      z-index: 0;
    }
  }

  .right-column.active {
    bottom: 0;
  }

  @media (min-width: 768px) {
    .right-column {
      bottom: 0; /* Keep it visible on larger screens */
      position: relative;
    }
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 40% 60%;
    overflow: hidden;
    background-color: var(--gray-50);

    .left-column,
    .right-column {
      overflow-y: scroll;
    }
  }

  @media (min-width: 1200px) {
    margin: auto 8rem;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
