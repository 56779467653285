.number-section {
    // border: 1px solid white;
    @media (min-width: 1200px) {
      position: absolute;
      right: 0;
      bottom: 0;
      // display: flex;
      // border: 1px solid white;
    }

    .text {
      font-size: 1rem;
      text-align: left;
      // border: 1px solid red;
    }
    .number {
      // border: 1px solid green;
      // border: 1px solid white;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 2rem 0;
      margin: 2.5rem 0;

      @media (min-width: 1200px) {
        margin: 2rem 0 0 0;
      }

      .option {
        width: calc(50% - 20px);
        display: flex;
        column-gap: 1rem;
        @media (min-width: 768px) {
          justify-content: center;
          width: calc(25% - 0px);
          flex-direction: row;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            height: 1.8rem;
            width: 1.8rem;
            color: var(--blue-50);
          }
        }

        .details {
          .count {
            font-weight: 600;
            font-size: 1.2rem;
            // border: 2px solid white;
          }
          .icon-title {
            font-size: 0.9rem;
            // border: 2px solid white;
            color: var(--white);
            font-weight: 500;
          }
        }

        @media (min-width: 1200px) {
          .icon {
            svg {
              height: 1.5rem;
              width: 1.5rem;
            }
          }

          .details {
            margin-right: 2rem;

            .count {
              font-size: 1.2rem;
              font-weight: 400;
              font-family: var(--heading-font);
            }
            .icon-title {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
    // @media (min-width: 992px) {
    //   display: flex;
    //   justify-content: space-between;

    //   .title {
    //     font-feature-settings: "rvrn" on, "hist" on, "cv03" on, "cv04" on,
    //       "cv15" on, "cv22" on, "liga" off, "clig" off, "calt" off;
    //     font-family: "Bebas Neue";
    //     font-size: 4rem;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 5.5rem;
    //   }

    //   .number-section {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;

    //     .number {
    //       background-color: var(--white);
    //       height: 4rem;
    //       width: 100%;
    //     }
    //   }
    // }
  }