.phone {
  // float: right;
  // height: 32px;
  // line-height: 32px;
  // font-size: 18px;
  // font-weight: bold;
  // color: #333333;
  // margin: 33px 0 33px 50px;
}
.phone svg {
  width: 14px;
  height: 14px;
  // float: left;
  margin-top: 6px;
  margin-left: 2px;
}
.phone svg path {
  fill: var(--blue-800);
}
.phone svg path:nth-child(2) {
  -webkit-animation: header-phone 2s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
  animation: header-phone 2s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
}
.phone svg path:nth-child(3) {
  -webkit-animation: header-phone2 2s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
  animation: header-phone2 2s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
}

/* Animate phone */

@-webkit-keyframes header-phone {
  0%,
  30% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
  80% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
  }
}
@keyframes header-phone {
  0%,
  30% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
  80% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes header-phone2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
  70%,
  90% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
  }
}
@keyframes header-phone2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
  70%,
  90% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
  }
}
