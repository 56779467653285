.navbar {
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  min-height: 3rem;
  z-index: 99999;
  background-color: var(--white);
  box-shadow: var(--box-shadow);

  @media (min-width: 1200px) {
    padding: 0.9rem 8rem;
  }

  @media (min-width: 768px) {
    z-index: 9999999;
  }

  .link {
    font-size: 0.86rem;
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
    a {
      text-decoration: none;
      color: var(--gray-500);
      margin-right: 2rem;

      &.active {
        background-color: var(--gray-100);
        color: var(--blue-600);

        border-radius: 10px;
        padding: 0.5rem 1rem;
      }
    }
  }

  .logo {
    width: 100%;

    .logo-btn {
      background-color: transparent;
      border: none;
    }
    img {
      display: flex;
      align-items: center;
      height: 1.2rem;
      margin-left: 1.6rem;
    }
    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
    }
    @media (min-width: 992px) {
      width: fit-content;
      margin-left: 6.8rem;
    }
  }

  .action-buttons {
    color: var(--white);

    .ham-icon {
      font-size: 1.5rem;
      cursor: pointer;
      display: block;
      color: var(--gray-500);
      display: flex;
      align-items: center;
    }

    .toggle-btns {
      display: none;
      column-gap: 1rem;
      @media (max-width: 992px) {
        position: fixed;
        top: 0;
        left: -100%;
        height: 100%;
        width: 70%;
        background-color: var(--gray-200);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
        z-index: 100;

        .btn-call-us,
        .btn-list-consultancy {
          height: 3rem;
          width: 15rem;
          margin-bottom: 1rem;
        }
      }

      .btn-call-us,
      .btn-list-consultancy {
        padding: 0.5rem 1rem;
        border-radius: 10px;
        cursor: pointer;
      }

      .btn-call-us {
        background-color: var(--blue-800);
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.5rem;

        .btn-icon {
          background-color: var(--gray-50);
          height: 1.5rem;
          width: 1.5rem;
          padding: 0.2rem;
          border-radius: 50px;
          display: flex;
          align-items: center;

          svg {
            color: var(--blue-800);
            font-size: 1rem;
          }
        }
        .text {
          .title-1 {
            font-size: 0.5rem;
          }
          .title-2 {
            font-size: 0.8rem;
            font-weight: 600;
            a {
              text-decoration: none;
              color: var(--white);
            }
          }
        }
      }

      .btn-list-consultancy {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: var(--blue-600);
        .btn-text {
          margin-right: 0.5rem;
          font-weight: 600;
          font-size: 0.9rem;
        }
        .btn-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 0.9rem;
          }
        }
      }

      &.open {
        left: 0;
        z-index: 999999;
      }
    }

    @media (min-width: 1000px) {
      .ham-icon {
        display: none;
      }
      .toggle-btns {
        display: flex;
      }
    }
  }

  // for popup
  .list-consultancy {
    .title-section {
      margin-bottom: 1rem;
      .title-1 {
        display: flex;
        flex-direction: row;
        column-gap: 0.5rem;
        .save-icon {
          display: flex;
          margin-top: 0.35rem;
          justify-content: center;
          svg {
            font-size: 1.4rem;
          }
        }
        .title-text {
          font-family: var(--heading-font);
          font-size: 1.6rem;
        }
      }
      .description {
        color: var(--gray-600);
        font-size: 0.9rem;
      }
    }

    form {
      font-family: var(--label-font);
      .input-box {
        display: flex;
        row-gap: 0.2rem;
        flex-direction: column;
        margin-bottom: 1rem;
      }

      label {
        font-weight: 400;
        font-size: 0.85rem;
        font-family: var(--label-font);
        color: var(--gray-900);

        span {
          color: var(--gray-500);
        }

        .error-msg {
          color: darkred;
          text-align: right;
        }
      }

      p {
        color: var(--gray-800);
        font-size: 0.8rem;
        font-family: var(--label-font);
      }
      input {
        background-color: var(--gray-100);
        padding: 0.8rem 1rem;
        border-radius: 10px;
        border: none;
        font-family: var(--label-font);

        &::placeholder {
          font-weight: 500;
          color: var(--gray-400);
          font-family: var(--label-font);
        }
      }

      .terms-box {
        margin-bottom: 1rem;
        font-size: 0.85rem;
        text-align: left;

        span {
          color: var(--blue-600);
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: var(--blue-800);
          }
        }
        input[type="checkbox"] {
          margin-right: 0.5rem;
          margin-bottom: 0.1rem;
          cursor: pointer;
          height: 1rem;
          width: 1rem;
        }
      }

      .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
        .cancel-btn,
        .submit-btn {
          width: 100%;
          padding: 0.5rem 1rem;
          font-size: 1rem;
          font-weight: 500;
          border-radius: 10px;
          text-align: center;
          cursor: pointer;
        }

        .cancel-btn {
          background-color: var(--gray-100);
        }

        .submit-btn {
          background-color: var(--blue-600);
          color: var(--white);
        }
      }
    }
  }

  // fot image popup
  .img-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .img-content {
      border-radius: 1rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      .benefit-img {
        height: 70vh;

        @media (min-width: 576px) {
          height: 90vh;
        }
      }

      .img-close-pop {
        position: absolute;
        top: 2rem;
        right: 2rem;
        background: none;
        border: none;
        cursor: pointer;
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;

        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }

  // for submission message
  .submission-message {
    margin-top: 10px;
    padding: 1rem 2rem;
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
    position: absolute;
    right: 1rem;

    &.success {
      background-color: #d4edda;
      color: #155724;
    }

    &.error {
      background-color: #f8d7da;
      color: #721c24;
    }
  }
}
