@font-face {
  font-family: Wanted Sans;
  src: url(../src/assets/fonts/WantedSans-1.0.1/variable/WantedSansVariable.ttf);
}

:root {
  --blue-800: #002f61;
  --blue-600: #176ecc;
  --blue-400: #007aff;
  --blue-200: #a9d3ff;
  --blue-50: #d9ebff;
  --black: #000000;
  --white: #ffffff;
  --gray-900: #0f172a;
  --gray-800: #1e293b;
  --gray-700: #334155;
  --gray-600: #475569;
  --gray-500: #64738b;
  --gray-400: #94a2bb;
  --gray-300: #cbd5e1;
  --gray-200: #e2e8f0;
  --gray-100: #f1f5f9;
  --gray-50: #f8fafc;
  --grad-midnight: linear-gradient(90deg, #176ecc 0%, #002f61 100%);

  /* Borders and Shadows */
  --border-radius: 4px;
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


  --heading-font: "Bebas Neue";
  --label-font: "Wanted Sans";
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  font-family: var(--label-font);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--background-color);
}
