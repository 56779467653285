.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;

  .popup-content {
    background-color: white;
    padding: 1.2rem;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: 90vh;
    max-width: 90vw;
    .popup-header {
      // margin-bottom: 1rem;

      position: relative;
      .popup-title {
        font-size: 1.2rem;
        color: var(--blue-color);
        margin-left: 1rem;
      }

      .popup-btn {
        .close-btn {
          position: absolute;
          top: 0;
          right: 0;
          background: none;
          border: none;
          cursor: pointer;
          font-size: 1.5rem;
          font-weight: 800;
          color: #666;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #333;
            transform: scale(1.02);
          }
        }
      }
    }

    .popup-main {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
