.how-it-works {
  z-index: 9;
  &.hidden {
    display: none;
  }
  @media (min-width: 768px) {
    padding: 2rem 8rem;
  }
  .title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      img {
        margin-right: 1rem;
        height: 2.4rem;
      }
      .text {
        color: var(--blue-800);
        font-family: var(--heading-font);
        font-size: 2.2rem;
        font-weight: 400;
      }
    }

    .btn-find-consultancies {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--blue-600);
      color: var(--white);
      width: fit-content;
      height: 2.6rem;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      box-shadow: 0px 3px 6px 0px rgba(14, 109, 253, 0.24);
      display: none;

      @media (min-width: 768px) {
        display: flex;
      }
      .btn-text {
        margin-right: 1rem;
        font-weight: 600;
        font-size: 0.9rem;
      }
      .btn-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .steps {
    margin: 0rem 0;

    @media (min-width: 1200px) {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
    }

    .step {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: var(--white);
      padding: 1.5rem;
      margin: 2rem 0 2rem 0;
      height: 15rem;
      border-radius: 15px;
      box-shadow: var(--box-shadow);
      // transition: transform 0.3s ease-in-out;
      // animation: fadeIn 1s ease-in-out;

      // &:hover {
      //   transform: scale(1.05);
      // }

      img {
        height: 3rem;
        width: 3rem;

        @media (min-width: 768px) {
          height: 4rem;
          width: 4rem;
        }
      }

      h3 {
        font-family: var(--heading-font);
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.5rem;
        color: var(--blue-600);
        margin: 1rem 0;
      }

      p {
        color: var(--gray-500);
        font-size: 1rem;
      }
    }
  }
}
