.text-2 {
  position: relative;
  display: flex;
  cursor: pointer;
  height: 1.8rem;
  align-items: center;

  .district-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    svg {
      font-size: 1.2rem;
      color: var(--blue-600);
    }
  }

  .district-box {
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: var(--blue-600);

    .district-title {
      font-size: 0.8rem;
      .district-title-init {
        font-size: 0.9rem;
        font-weight: 600;
        min-width: 9.5rem;
      }
    }

    .district {
      font-size: 0.85rem;
      font-weight: 500;
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 10rem;
      overflow: auto;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1;
      margin-top: 4px;
      border-radius: 10px;

      // Custom scrollbar styles
      &::-webkit-scrollbar {
        width: 10px;
        height: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--gray-400);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--gray-100);
      }

      .dropdown-item {
        padding: 8px;
        cursor: pointer;
        font-size: 0.9rem;
        text-align: center;
        color: var(--gray-900);

        &:hover {
          background-color: var(--blue-50);
        }
      }
    }
  }
}
