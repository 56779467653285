.student-request {
  .title-section {
    margin-bottom: 1rem;
    .title-1 {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
      .save-icon {
        display: flex;
        margin-top: 0.19rem;
        justify-content: center;
        svg {
          font-size: 1.2rem;
        }
      }
      .title-text {
        font-family: var(--heading-font);
        font-size: 1.2rem;
      }
    }
    .description {
      color: var(--gray-600);
      font-size: 0.8rem;
    }
  }

  form {
    font-family: var(--label-font);
    .input-box {
      display: flex;
      row-gap: 0.2rem;
      flex-direction: column;
      margin-bottom: 0.8rem;
    }

    label {
      font-weight: 550;
      font-size: 0.77rem;
      font-family: var(--label-font);
      color: var(--gray-900);

      span {
        color: var(--gray-500);
      }

      .error-msg {
        color: darkred;
        text-align: right;
      }
    }

    p {
      color: var(--gray-800);
      font-size: 0.8rem;
      font-family: var(--label-font);
    }
    input,
    textarea {
      background-color: var(--gray-100);
      padding: 0.75rem 1rem;
      border-radius: 10px;
      border: none;
      font-family: var(--label-font);

      &::placeholder {
        font-weight: 500;
        color: var(--gray-400);
        font-family: var(--label-font);
      }
      &:focus {
        border-color: blue;
        border-width: 1px;
      }
    }

    .terms-box {
      margin: 1rem 0;
      text-align: left;
      display: flex;
      column-gap: 0.5rem;
      align-items: center;

      p {
        font-weight: 500;
        font-size: 0.8rem;
        span {
          color: var(--blue-600);
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: var(--blue-800);
          }
        }
        input[type="checkbox"] {
          margin-right: 0.5rem;
          cursor: pointer;
          height: 0.9rem;
          width: 0.9rem;
        }
      }
    }

    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      column-gap: 1rem;
      .cancel-btn,
      .submit-btn {
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
      }

      .cancel-btn {
        background-color: var(--gray-100);
      }

      .submit-btn {
        background-color: var(--blue-600);
        color: var(--white);
      }
    }
  }
}
