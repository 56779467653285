$secondary-color: #fdb827;

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--gray-50);

  .loader-text {
    font-size: 2rem;
    color: var(--blue-800);
    margin-bottom: 0rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-family: var(--heading-font);
  }

  .loading-line {
    position: relative;
  }
}
