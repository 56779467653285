.dropdown-container {
  position: relative;
  border-radius: 15px;
  width: 100%;

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--gray-50);
    border-radius: 15px;
    padding: 0.8rem 1rem;
    cursor: pointer;
    // for large screen
    // width: 100%;

    .dropdown-title {
      display: flex;
      flex-direction: row;
      color: var(--gray-500);
      font-size: 0.94rem;
      font-weight: 500;
      .icon {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        color: var(--black);

        img {
          height: 1.4rem;
        }

        &:nth-child(1) {
          font-size: 1.4rem;
        }
      }
      .count-pill {
        background-color: var(--blue-400);
        border-radius: 4px;
        height: 1.2rem;
        width: 1.2rem;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-family: var(--label-font);
        font-size: 0.8rem;
        font-weight: 300;
      }
    }

    .toggle-arrow {
      display: flex;
      align-items: center;
      color: var(--gray-500);
      .arrow-wrapper {
        transition: all 0.3s ease;
        svg {
          display: flex;
          align-items: center;
          color: var(--gray-500);
          font-size: 1.2rem;
        }
      }
      .arrow-wrapper.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-content {
    background-color: var(--white);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 15px;
    z-index: 9999;
    box-shadow: var(--box-shadow);
    padding: 0.5rem;

    .input-container {
      background-color: var(--gray-100);
      border-radius: 10px;
      padding: 0.5rem 0.6rem;
      .search-input {
        background-color: var(--gray-100);
        border: none;
        width: calc(100% - 10px);

        &:focus {
          outline: none;
          border: none;
        }
      }
    }

    .options-container {
      max-height: 200px;
      overflow-y: auto;
      // Custom scrollbar styles
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--gray-400);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--gray-100);
      }

      .option {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        margin: 0.5rem 0 0 0;
        font-size: 0.9rem;
        &:hover {
          background-color: var(--blue-50);
        }

        input[type="checkbox"] {
          margin-right: 0.5rem;
          accent-color: var(--blue-600);

          &:hover {
            background-color: var(--blue-50);
          }
        }

        .option-checked {
          background-color: var(--blue-800);
          color: var(--blue-600);
        }
      }
    }
  }
}
