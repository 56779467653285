.footer {
  background-image: var(--grad-midnight);
  color: var(--white);
  border-radius: 25px;
  padding: 4rem 2rem;

  &.hidden {
    display: none;
  }

  @media (min-width: 1200px) {
    padding: 4rem 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-links {
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-evenly;
    }

    .link-column {
      margin-bottom: 2rem;
      @media (min-width: 1200px) {
        margin-bottom: 0;
        margin-right: 4rem;
      }
      h3 {
        font-family: var(--heading-font);
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.5rem;
        margin-bottom: 0.5rem;
      }

      ul {
        li {
          margin-bottom: 0.5rem;
          a {
            text-decoration: none;
            color: white;
            font-size: 0.95rem;
            font-weight: 500;
            line-height: 1.5rem;
          }
        }
      }
    }
  }

  .footer-socials {
    .social-links {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: row;

      @media (min-width: 768px) {
        justify-content: center;
      }

      @media (min-width: 1200px) {
        justify-content: left;
      }

      a {
        margin-right: 1rem;
        background-color: var(--white);
        height: 2.3rem;
        width: 2.3rem;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 1.2rem;
          color: var(--black);
        }
      }
    }
  }

  .copyright {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;

    @media (min-width: 768px) {
      text-align: center;
    }
    @media (min-width: 1200px) {
      text-align: left;
    }
  }
}
