.consultancy-card.skeleton {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-100);
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  animation: pulse 1.5s infinite;

  .row-1 {
    display: flex;
    column-gap: 0.5rem;
    justify-content: space-between;
    margin-top: 0.5rem;

    .image.skeleton-box {
      width: 3rem;
      height: 3rem;
      background: linear-gradient(90deg, var(--gray-300), var(--gray-200), var(--gray-300));
      border-radius: 10px;
      animation: pulse 1.5s infinite;
    }

    .title {
      flex-grow: 1;

      .text.skeleton-box {
        height: 1.5rem;
        width: 70%;
        background: linear-gradient(90deg, var(--gray-300), var(--gray-200), var(--gray-300));
        border-radius: 4px;
        animation: pulse 1.5s infinite;
      }

      .other.skeleton-box {
        height: 1rem;
        width: 40%;
        background: linear-gradient(90deg, var(--gray-300), var(--gray-200), var(--gray-300));
        border-radius: 4px;
        animation: pulse 1.5s infinite;
      }
    }

    .sortlist-btn.skeleton-box {
      width: 2rem;
      height: 2rem;
      background: linear-gradient(90deg, var(--gray-300), var(--gray-200), var(--gray-300));
      border-radius: 50%;
      animation: pulse 1.5s infinite;
    }
  }

  .row-2 {
    display: flex;
    margin-top: 0.5rem;

    .other.skeleton-box {
      height: 1rem;
      width: 100%;
      background: linear-gradient(90deg, var(--gray-300), var(--gray-200), var(--gray-300));
      border-radius: 4px;
      animation: pulse 1.5s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
