.sortlist-popup {
  position: fixed;
  top: 84%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  width: 90%;
  z-index: 9999;

  @media (min-width: 768px) {
    width: 70%;
    top: 88%;
    z-index: 99999;
  }
  @media (min-width: 992px) {
    width: 55%;
  }
  &.hidden {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      .title-box {
        @media (min-width: 768px) {
          display: flex;
          flex-direction: row;
        }
      }

      .text {
        display: flex;
        align-items: center;
        .sub-text {
          font-family: var(--heading-font);
          font-size: 1.3rem;
          margin-bottom: 0.2rem;
          min-width: fit-content;
        }
        img {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.2rem;
          margin-right: 0.5rem;
        }
      }

      .count {
        width: 100%;
        display: flex;
        align-items: center;

        @media (min-width: 768px) {
          width: fit-content;
          margin-left: 0.5rem;
        }

        .count-pill {
          width: fit-content;
          background-color: var(--blue-50);
          border-radius: 5px;
          padding: 0.2rem 0.5rem;
          font-size: 0.8rem;
          font-weight: 600;
          color: var(--blue-600);
        }
      }
    }
    .clear-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        border: none;
        background-color: white;
        font-weight: 500;
        color: red;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    .sortlist-images {
      padding: 0.8rem 0;
      display: flex;
      overflow-x: auto;

      @media (min-width: 768px) {
        padding: 0.8rem 0 0 0;
      }

      div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 10px;
          margin: 5px 5px 0 0;
          border: 1px solid var(--blue-50);
          object-fit: contain;
        }

        .close-btn {
          position: absolute;
          top: 0px;
          right: 0;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.3rem;
          border-radius: 50%;
          color: red;
        }
      }
    }
    .request-btn {
      display: flex;
      align-items: center;
      justify-content: center;

      .submit-btn {
        width: 100%;
        padding: 0.5rem 1rem;
        background-color: var(--blue-400);
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 600;
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: end;
      justify-content: space-between;

      .request-btn {
        .submit-btn {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}
