.consultancy-card {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--gray-50);
  padding: 0.5rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: var(--gray-50);
  }

  .row-1 {
    display: flex;
    column-gap: 0.5rem;
    justify-content: space-between;
    margin-top: 0.5rem;

    .image {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      img {
        height: 3rem;
        width: 3rem;
        object-fit: contain;
        border-radius: 10px;
        box-shadow: var(--box-shadow);
      }
    }
    .title {
      flex-grow: 1;
      .text {
        font-weight: 500;
        font-size: 1.2rem;
        font-family: var(--heading-font);
      }
      .other {
        display: flex;
        .ecan {
          display: flex;
          .icon {
            img {
              margin-right: 0.2rem;
            }
          }
          .text {
            font-family: var(--label-font);
            font-size: 0.8rem;
            color: var(--blue-600);
            font-weight: 500;
          }
        }
      }
    }

    .sortlist-btn {
      display: flex;
      align-items: center;

      flex-shrink: 0;

      img {
        padding: 0.5rem 0.6rem;
        height: 2.4rem;
        border-radius: 10px;
        &:hover {
          background-color: var(--blue-50);
        }
      }
    }
  }
  .row-2 {
    display: flex;
    margin-top: 0.5rem;
    .other {
      display: flex;
      .pan {
        display: flex;
        align-items: center;
        column-gap: 0.3rem;
        .number {
          color: var(--gray-500);
          font-size: 0.8rem;
        }
      }
    }
  }
}
