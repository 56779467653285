.filter {
  background-color: var(--white);
  padding: 1rem;
  border-radius: 10px;
  color: var(--black);
  transition: transform 0.3s ease, padding 0.3s ease;

  &.browse {
    padding-top: 0.1rem;
    border-bottom: 1px solid var(--gray-50);
    position: fixed;
    transform: translateY(-0.1rem);
    left: 0;
    right: 0;

    @media (min-width: 768px) {
      z-index: 999999;
    }

    .title {
      display: none;
    }
    .text-1 {
      display: none;
    }

    .dropdown-box {
      .popular-options {
        display: none;
      }
    }
  }

  &.howitworks {
    @media (min-width: 1200px) {
      padding: 2rem;
    }
  }

  .title {
    display: flex;

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .text-1 {
      display: flex;
      align-items: center;
      font-family: var(--heading-font);
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
      img {
        height: 2rem;
        width: 2rem;
        margin-right: 0.5rem;
      }
    }

    .text-2 {
      display: none;

      @media (min-width: 1200px) {
        display: flex;
      }
    }
  }

  .dropdown-box {
    .dropdown-options {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      margin-top: 1rem;

      @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: space-evenly;
        column-gap: 1rem;
        // margin-top: 0;

        .popular-options {
          .options {
            flex-direction: row;
            flex-wrap: 1;
            justify-content: left;
            column-gap: 1rem;
          }
        }
      }

      .sm-drop {
        &.howitworks {
          @media (min-width: 1200px) {
            .text-2 {
              display: none;
            }
          }
          .slider-btn {
            display: none;
          }
        }

        &.browse {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: auto 3rem;
        }

        .slider-btn {
          display: flex;
          column-gap: 0.5rem;
          align-items: center;
          justify-content: center;
          color: var(--blue-400);
          font-family: var(--label-font);
          font-weight: 500;
          font-size: 0.9rem;
          background-color: transparent;
          border: none;

          @media (min-width: 1200px) {
            display: none;
          }
        }
      }

      .filter-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--blue-400);
        color: var(--white);
        padding: 0.8rem 1rem;
        border-radius: 10px;
        cursor: pointer;

        .btn-text {
          margin-right: 1rem;
          font-weight: 600;
          font-size: 0.9rem;
        }
        .btn-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .popular-options {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      margin-top: 1rem;
      .title {
        font-family: var(--heading-font);
        font-size: 1.4rem;
        color: var(--gray-400);
      }
      .options {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        @media (min-width: 1200px) {
          flex-direction: row;
          column-gap: 1rem;
        }

        .option {
          display: flex;
          flex-direction: row;
          column-gap: 1rem;
          padding: 0.25rem;
          border-radius: 0.75rem;
          border: 1px solid var(--gray-100);
          background-color: var(--gray-50);
          img {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            height: 2.5rem;
            width: 3rem;
            object-fit: cover;
          }
          p {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
