.consultancy-detail-skeleton {
    border-radius: var(--border-radius);
    background-color: var(--white);
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .close-box-skeleton {
        align-self: flex-end;
        height: 1.4rem;
        width: 1.4rem;
        border-radius: 5px;
        background-color: var(--gray-300);
    }

    .header-skeleton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 2.5rem;
        width: 100%;
        background-color: var(--gray-200);
        border-radius: 5px;

        .title-skeleton {
            height: 100%;
            width: 60%;
            background-color: var(--gray-300);
            border-radius: 4px;
        }

        .button-skeleton {
            width: 20%;
            height: 1.8rem;
            background-color: var(--gray-300);
            border-radius: 5px;
        }
    }

    .statistic-skeleton {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        .box-skeleton {
            height: 3rem;
            background-color: var(--gray-100);
            border-radius: 10px;
        }
    }

    .description-skeleton {
        height: 5rem;
        width: 100%;
        background-color: var(--gray-200);
        border-radius: 6px;
        margin: 1rem 0;
    }

    .destination-skeleton {
        .title-skeleton {
            height: 2rem;
            margin-bottom: 0.5rem;
            width: 100%;
            background-color: var(--gray-300);
            border-radius: 5px;
        }

        .country-list-skeleton {
            display: flex;
            overflow: hidden;
            gap: 10px;

            .country-skeleton {
                height: 100px;
                width: 150px;
                border-radius: var(--border-radius);
                background-color: var(--gray-200);
            }
        }
    }

    .university-skeleton,
    .course-skeleton {
        .title-skeleton {
            height: 2rem;
            margin-bottom: 0.5rem;
            background-color: var(--gray-300);
            border-radius: 5px;
        }

        .pill-skeleton {
            height: 2rem;
            width: calc(100% - 10px);
            background-color: var(--gray-100);
            border-radius: 6px;
            margin: 5px 0;
        }
    }

    .shimmer {
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
            animation: shimmer 1.5s infinite;
        }
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
