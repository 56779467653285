.consultancy-detail {
  border-radius: var(--border-radius);
  background-color: var(--white);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;


  .close-box {
    display: flex;
    justify-content: flex-end;

    button {
      background: var(--gray-200);
      border: none;
      height: 1.4rem;
      width: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      svg {
        color: var(--gray-600);
        font-size: 1.2rem;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: var(--heading-font);
      font-size: 1.8rem;
      font-weight: 600;
      color: var(--blue-800);
    }

    .sortlist-btn {
      margin-left: 0.5rem;
      button {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 6px;
        background-color: transparent;
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        font-family: var(--label-font);
        color: var(--blue-600);

        img {
          height: 1rem;
          margin-right: 0.5rem;
        }

        &:hover {
          background-color: var(--blue-50);
        }
      }

      &.isSortlisted {
        button {
          background-color: var(--blue-50);
        }
      }
    }
  }

  .statistic {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;

    .box {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      background: var(--gray-200);
      padding: 0.5rem 1rem;
      border-radius: 10px;
      font-family: var(--label-font);

      .box-2 {
        display: flex;
        align-items: center;
        column-gap: 0.8rem;
        img {
          height: 1rem;
          width: 1rem;
        }
        .content {
          font-size: 0.8rem;
          .text-1 {
            font-weight: 600;
          }
          .text-2 {
            font-size: 0.7rem;
            color: var(--gray-500);
            height: 0.7rem;
          }
        }
      }

      &.verified {
        .content {
          .text-1 {
            color: var(--blue-600);
          }
        }
      }
    }
  }

  .description {
    margin: 1rem 0;
    font-size: 0.9rem;
    button {
      margin-top: 1rem;
      background-color: var(--gray-300);
      border: none;
      border-radius: 6px;
      color: var(--gray-800);
      padding: 0.4rem 0.8rem;
      font-weight: 600;
      font-family: var(--label-font);
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: var(--gray-400);
      }
    }
  }

  .destination {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: var(--heading-font);
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--blue-800);

      .arrows {
        button {
          background: transparent;
          border: none;
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
    }

    .country-list {
      display: flex;
      overflow: hidden;
      gap: 10px;

      .country {
        position: relative;
        width: 150px;
        height: 100px;
        border-radius: var(--border-radius);
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: #e0e0e0;

        .country-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .country-placeholder {
          width: 100%;
          height: 100%;
          background-color: #f0f0f0;
        }

        .country-name {
          position: absolute;
          bottom: 8px;
          left: 8px;
          color: white;
          background-color: rgba(0, 0, 0, 0.6);
          padding: 3px 6px;
          border-radius: 4px;
          font-size: 0.8rem;
          font-weight: 400;
          font-family: var(--label-font);
        }
      }
    }
  }

  .university {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: var(--heading-font);
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--blue-800);

      button {
        margin-left: 1rem; 
        padding: 0.3rem 0.8rem; 
        font-size: 0.9rem; 
        background-color: var(--primary); 
        color: var(--white); 
        border: none; 
        border-radius: 6px;
        cursor: pointer; 
        transition: background-color 0.3s ease; 

        &:hover {
          background-color: var(--primary-light); 
        }
      }
    }

    .university-list {
      display: flex; 
      justify-content: center; 

      .pill {
        display: inline-block; 
        width: calc(100% - 10px); 
        margin: 5px; 
        background-color: var(--gray-200); 
        padding: 0.5rem 1rem;
        border-radius: 6px; 
        font-family: var(--heading-font);
        font-size: 1.2rem; 
        color: var(--gray-800); 
        transition: background-color 0.3s ease;

        &:hover {
          background-color: var(--gray-300); 
          cursor: pointer; 
        }
      }
    }
  }

  .course {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: var(--heading-font);
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--blue-800);
    }
    .pill,
    .course-pill {
      display: inline-block;
      width: calc(100% - 10px);
      margin: 5px 0;
      background-color: var(--gray-200);
      padding: 0.5rem 1rem;
      border-radius: 6px;
      font-family: var(--heading-font);
      font-size: 1.2rem;
      color: var(--gray-800);

      .course-point {
        margin-top: 0.5rem;
        ul {
          display: flex;
          column-gap: 1.5rem;
          flex-direction: row;
          li {
            font-size: 0.7rem;
            font-family: var(--label-font);
            font-weight: 500;
            color: var(--gray-500);
          }
        }
      }
    }
  }
}
